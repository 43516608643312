.board {
  background: url("../assets/board.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  aspect-ratio: 0.8724;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position:relative;
}

.board_main {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex: 1;
}

.board_left {
  flex: 1;
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-right: 0.1%;
}

.board_right {
  flex: 1;
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-left: 0.1%;
}

.board_top {
  height: 3.8%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 3;
}

.board_bottom {
  width: 100%;
  height: 8.54%;
  padding: 2.2%;
  display: flex;
  flex-direction: row;
}

.board_container {
  flex: 1;
  padding-left: 3.5%;
  padding-right: 3.5%;
  padding-top: 3.5%;
  padding-bottom: 5%;
  display: flex;
  flex-direction: column;
  height: 85.46%;
}

.board_columns {
  flex: 1;
  display: flex;
  flex-direction: row;
  height: 50%;
}

.board_columns_bottom .board_column {
  flex-direction: column-reverse;
}

.board_column {
  width: 16.6666%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.board_checker {
  width: 100%;
  aspect-ratio: 1;
}

.board_columns_top .board_checker:not(:first-child) {
  margin-top: -50%;
}
.board_columns_bottom .board_checker:not(:last-child) {
  margin-top: -50%;
}

.board_checker_white {
  background: url("../assets/checker-white.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.board_checker_black {
  background: url("../assets/checker-black.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.board_exited_checker {
  height: 100%;
  aspect-ratio: 0.296;
}

.board_exited_checker_white {
  background: url("../assets/checker-white-side.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.board_exited_checker_black {
  background: url("../assets/checker-black-side.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.board_dice_container {
  position: absolute;
  left: 48%;
  width: 8%;
  top: 45.5%;
  display: flex;
  z-index: 5;
}
.board_dice_container.left{
  left: 20.9%;
}
.board_dice_container.right{
  left: 70.9%;
}

.board_dice {
  width: 100%;
  aspect-ratio: 1;
  z-index: 2;
}

.board_player {
  margin-top: 0.2%;
  margin-left: 1%;
  margin-right: 1%;
  font-size: 1.3rem;
  color: white;
}
.board_icon{
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1.5rem;
}
.board_controls {
  margin-top: 0.7%;
  color: white;

  display: flex;
  flex-direction: row;
}
.board_player.selected {
  color: green;
}
//@media (max-width: 992px) {
  .board_player {
    margin-top: 0.5%;
    margin-left: 1%;
    margin-right: 1%;
    font-size: 0.9rem;
  }
  .board_icon{
    width: 1.5rem;
    height: 1.5rem;
    font-size: 1.5rem;
  }
//}


.board_bar{
  position: absolute;
  width: 8%;
  left: 46%;
  height: 39.5%;
  top: 27%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.board_bar_top{
  display: flex;
  height: 50%;
  flex-basis: fit-content;
  flex-direction: column;
}
.board_bar_bottom{
  display: flex;
  height: 50%;
  flex-basis: fit-content;
  justify-content: flex-end;
  flex-direction: column;
}
.board_bar_top .board_checker:not(:first-child) {
  margin-top: -50%;
}
.board_bar_bottom .board_checker:not(:first-child) {
  margin-top: -50%;
}