// Here you can add other styles
.avatar-img {
  height: 40px;
  object-fit: cover;
}

.w-unset {
  width: unset;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.ml-4 {
  margin-left: 1rem !important;
}

.mr-4 {
  margin-right: 1rem !important;
}

.float-right {
  float: right;
}
.cursor_pointer{
  cursor: pointer;
}