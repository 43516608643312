.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.td-nowrap {
    white-space: nowrap;
}

.semi-opacity {
    opacity: 0.5;
}

.board {

}

.select-inline {
    width: unset !important;
}

.money_container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: center;
}

.money_icon {
    width: 15px;
    align-self: center;
    margin-left: 5px;
}

.toast-body {
    word-wrap: anywhere !important;
}

.date-time-picker input {
    border-radius: 0.25rem;
}

.date-time-picker input:focus {
    border: 1px solid rgba(164, 167, 181, 1);
    box-shadow: none;
}

.header-nav .show > .nav-link, .header-nav .nav-link.active {
    background: rgba(44, 56, 74, 0.1);
    border-radius: 10px;
}

.clickable {
    cursor: pointer;
}

.body {
    position: relative;
}

.back-line {
    position: absolute;
    left: 0;
    top: -24px;
    height: 100vh;
    width: 50px;
    background: rgba(44, 56, 74, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    cursor: pointer;
}

.back-line:hover {
    background: rgba(44, 56, 74, 0.2);
}
.back-line svg{
    height: 3em;
}

@media (max-width: 1100px) {
    .back-line{
        display: none;
    }
}
.text-right{
    text-align: right;
}
.vh-100 {
    min-height: 100vh;
}
.dropdown-item{
    cursor: pointer;
}
.dropdown .btn{
    height: 38px;
}